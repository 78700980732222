import React, { useEffect, useRef, useState } from "react";
import "../DataCounter/dataCounters.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Niteen from "assets/images/authors/niteen.png";
import Riteeka from "assets/images/authors/reetika.png";
import Krishna from "assets/images/authors/krishna.png";
import Amrut from "assets/images/authors/amrut.png";
import Ishank from "assets/images/authors/ishank.png";
import Venketesh from "assets/images/authors/venketesh.png";

function PeopleSays() {
  const ref = useRef();

  const [yOffset, setYOffset] = useState();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setYOffset(240); // Adjusted offset for small screens
    } else {
      setYOffset(-110); // Default offset for larger screens
    }
  }, [location]);

  useEffect(() => {
    if (location.hash.includes("testimonial")) {
      const element = ref.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const targetY = rect.top + scrollTop + yOffset;
        window.scrollTo({ top: targetY, behavior: "smooth" });
      }
    }
  }, [location, ref, yOffset]);

  // useEffect(() => {
  //   if (location.hash.includes("testimonial")) {
  //     const yOffset = -110; // Default offset
  //     const element = ref.current;
  //     if (element) {
  //       const rect = element?.getBoundingClientRect();
  //       const scrollTop =
  //         window.pageYOffset || document?.documentElement.scrollTop;
  //       const targetY = rect.top + scrollTop + yOffset;
  //       window.scrollTo({ top: targetY, behavior: "smooth" });
  //     }
  //   }
  // }, [location]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 2.96,
    slidesToScroll: 1,
    pauseOnFocus: true,
    pauseOnHover: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 1.98,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          infinite: true
        }
      },
      // {
      //   breakpoint: 1340,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     infinite: true
      //   }
      // },
      {
        breakpoint: 1039,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.03,
          slidesToScroll: 1,
          infinite: true
        }
      },

      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1.06,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1.099,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.0,
          slidesToScroll: 1,
          infinite: true
        }
      }

      // {
      //   breakpoint: 992,
      //   settings: {
      //     slidesToShow: 1.96,
      //     slidesToScroll: 1,
      //     infinite: true
      //   }
      // },
      // {
      //   breakpoint: 770,
      //   settings: {
      //     slidesToShow: 0.98,
      //     slidesToScroll: 1,
      //     infinite: true
      //   }
      // }
    ]
  };

  return (
    <section
      ref={ref}
      id="testimonials"
      className="container people-says-container"
    >
      <div className="title-container" id="testimonial">
        <h2>What People</h2>
        <h3>Say About Us</h3>
      </div>
      <div className="slider-container">
        <Slider {...settings} className="slider">
          <div className="people-opinion">
            <p className="opinion">
              Trainer showed Ab Initio concepts effectively in practical
              examples. Happy with the course
            </p>
            <div className="author-container">
              <img src={Niteen} alt="" />
              <div>
                <h4 className="author-name">Niteen </h4>
                <p className="author-role">Barclays</p>
              </div>
            </div>
          </div>
          <div className="people-opinion">
            <p className="opinion">
              Trainer showed Ab Initio concepts effectively in practical
              examples. Happy with the course
            </p>
            <div className="author-container">
              <img src={Riteeka} alt="" />
              <div>
                <h4 className="author-name">Reetika</h4>
                <p className="author-role">Barclays</p>
              </div>
            </div>
          </div>
          <div className="people-opinion">
            <p className="opinion">
              Trainer is the best, shares knowledge effectively, clears doubts
              well. Highly valuable, recommend more sessions
            </p>
            <div className="author-container">
              <img src={Krishna} alt="" />
              <div>
                <h4 className="author-name">Krishna</h4>
                <p className="author-role">_VOIS</p>
              </div>
            </div>
          </div>
          <div className="people-opinion">
            <p className="opinion">
              I liked the content, well-planned topics, and Suhast&apos;s
              expertise highlighted the training
            </p>
            <div className="author-container">
              <img src={Amrut} alt="" />
              <div>
                <h4 className="author-name">Amrut</h4>
                <p className="author-role">Accenture</p>
              </div>
            </div>
          </div>
          <div className="people-opinion">
            <p className="opinion">
              Thanks Suhast for the wonderful session. It was an interactive and
              knowledgeable one, we took the most out of it
            </p>
            <div className="author-container">
              <img src={Ishank} alt="" />
              <div>
                <h4 className="author-name">Ishank</h4>
                <p className="author-role">_VOIS</p>
              </div>
            </div>
          </div>
          <div className="people-opinion">
            <p className="opinion">
              Training beneficial even for Ab Initio admin. I will recommend
              Suhast Joshi for future GDE trainings
            </p>
            <div className="author-container">
              <img src={Venketesh} alt="" />
              <div>
                <h4 className="author-name">Venkatesh</h4>
                <p className="author-role">_VOIS</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default PeopleSays;
